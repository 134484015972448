<template>
  <footer>
    <div class="footer-wrap">
      <div class="footer-links-block footer-logo">
        <div class="nav-title">
          <!-- <div class="title-logo"></div>
          <div class="title-text">
            <h1>ЦПМСД №2</h1>
            <span>деснянського района м. Києва</span>
          </div>-->
        </div>
      </div>
      <div class="footer-links-block">
        <h4>КАРТА САЙТУ</h4>
        <span @click.prevent="redirectTo('/public-documents')">Публічна інформація</span>
        <span @click.prevent="redirectTo('/contacts')">Контакти</span>
        <span @click.prevent="redirectTo('/structure')">Структура</span>
        <span @click.prevent="redirectTo('/life')">Безбар'єрність</span>
        <span @click.prevent="redirectTo('/news')">Новини</span>
      </div>
      <div class="footer-links-block">
        <h4>РОЗКЛАД РОБОТИ</h4>
        <div class="link-group">
          <a>Пн-Пт – 8:00-20:00</a>
          <a>Сб-Нд – Вихідний</a>
        </div>
        <a
          target="_blank"
          href="https://www.google.com/maps/place/%D0%A6%D0%B5%D0%BD%D1%82%D1%80+%D0%BF%D0%B5%D1%80%D0%B2%D0%B8%D0%BD%D0%BD%D0%BE%D1%97+%D0%BC%D0%B5%D0%B4%D0%B8%D0%BA%D0%BE-%D1%81%D0%B0%D0%BD%D1%96%D1%82%D0%B0%D1%80%D0%BD%D0%BE%D1%97+%D0%B4%D0%BE%D0%BF%D0%BE%D0%BC%D0%BE%D0%B3%D0%B8+%E2%84%962+%D0%94%D0%B5%D1%81%D0%BD%D1%8F%D0%BD%D1%81%D1%8C%D0%BA%D0%BE%D0%B3%D0%BE+%D1%80%D0%B0%D0%B9%D0%BE%D0%BD%D1%83/@50.5088143,30.5943991,16.31z/data=!4m13!1m7!3m6!1s0x40d4d1a90a738def:0x73d8ee6133ad5c82!2z0LLRg9C70LjRhtGPINCi0LXQvtC00L7RgNCwINCU0YDQsNC50LfQtdGA0LAsIDE5LCDQmtC40ZfQsiwgMDIwMDA!3b1!8m2!3d50.5092381!4d30.5966098!3m4!1s0x0:0x9c522c9eac21f4e1!8m2!3d50.509197!4d30.596647"
        >вул. Рональда Рейгана, 19</a>
      </div>
      <div class="footer-links-block">
        <h4>КОНТАКТНА ІНФОРМАЦІЯ</h4>
        <a href="tel:+38(073) 537 1560">+38 (073) 537 1560</a>
        <a href="mailto:desnacpmsd2@kyivcity.gov.ua">desnacpmsd2@kyivcity.gov.ua</a>
        <div class="social-media">
          <a
            target="_blank"
            href="https://www.facebook.com/cpmsd2desna/"
            style="background-image: url('/assets/contacts/facebook.png');"
            class="social"
          ></a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  methods: {
    redirectTo(url) {
      window.scrollTo(0, 0);
      this.$router.push(url);
    }
  }
};
</script>
<style scoped>
footer {
  width: 100vw;
  min-height: 40vh;
  background-color: #a3a3a3;
}

.footer-wrap {
  margin: 0 auto;
  height: 100%;
  width: 100%;
  max-width: 1250px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  padding: 6vh 0;
  flex-wrap: wrap;
}

.footer-links-block {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: auto;
  align-items: flex-start;
  color: #1b1c1d;
  /* color: #6fc3eb; */
}

.footer-links-block h4 {
  font-size: 18px;
  margin-bottom: 2.5vh;
}

.footer-links-block h4::after {
  display: block;
  content: " ";
  width: 100%;
  height: 6px;
  background: #f20b2a;
}

.footer-links-block .link-group {
  margin: 0 0 15px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-links-block a,
.footer-links-block span {
  font-size: 16px;
  color: #1b1c1d;
  margin: 4px 0;
  text-align: left;
  cursor: pointer;
}

.footer-links-block > a::after,
.footer-links-block > .link-group > a::after,
.footer-links-block > span::after,
.footer-links-block > .link-group > span::after {
  display: block;
  content: " ";
  width: calc(100% - 5px);
  height: 1px;
  background-color: #1b1c1d;
  /* background-color: ivory; */
}

.footer-links-block .social-media {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
}

.footer-links-block .social-media a {
  height: 50px;
  width: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 8px 0 rgba(255, 255, 255, 0.35);
  transition: 0.3s;
}

.footer-links-block .social-media a:hover {
  box-shadow: 0px 0px 8px 0 rgba(255, 255, 255, 0.35),
    0px 0px 18px 0 rgba(255, 255, 255, 0.2),
    0px 0px 26px 0 rgba(255, 255, 255, 0.1);
  filter: saturate(30%);
}

.footer-links-block.footer-logo {
  display: flex;
  flex-direction: column;
  width: 450px;
  height: auto;
  align-items: flex-start;
  color: white;
}

.nav-title {
  height: 130px;
  width: 400px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../assets/logo/logo.png");
}

.title-logo {
  display: inline-block;
  width: 150px;
  height: 150px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../assets/logo/logo-transparent-bg.png");
}

.title-text {
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.title-text h1 {
  font-size: 34px;
}

.title-text span {
  font-size: 17px;
  opacity: 0.7;
}

@media screen and (max-width: 1200px) {
  .footer-wrap {
    width: 100%;
  }

  .footer-links-block {
    display: flex;
    flex-direction: column;
    width: 210px;
    margin: 10px;
    height: auto;
    align-items: flex-start;
  }

  .footer-links-block h4 {
    font-size: 16px;
    margin-bottom: 2.5vh;
  }

  .footer-links-block .link-group {
    margin: 0 0 15px 0;
    display: flex;
    flex-direction: column;
    align-items: space-between;
  }

  .footer-links-block a,
  .footer-links-block span {
    font-size: 15px;
  }

  .footer-links-block .social-media {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
  }

  .footer-links-block .social-media a {
    height: 40px;
    width: 40px;
  }

  .footer-links-block.footer-logo {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    align-items: flex-start;
    color: white;
  }

  .title-logo {
    width: 140px;
    height: 140px;
  }

  .title-text {
    height: auto;
  }

  .title-text h1 {
    font-size: 30px;
  }

  .title-text span {
    font-size: 15px;
  }
}

@media screen and (max-width: 1110px) {
  .footer-links-block.footer-logo {
    width: 100%;
  }

  .nav-title {
    height: 150px;
    width: 420px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 730px) {
  .footer-links-block {
    flex-direction: column;
    width: 94%;
    margin-left: 30px;
    height: auto;
    align-items: flex-start;
  }

  .footer-links-block h4 {
    font-size: 18px;
    margin-bottom: 2vh;
    margin-top: 4vh;
  }

  .footer-links-block a,
  .footer-links-block span {
    font-size: 17px;
  }

  .footer-links-block .social-media a {
    height: 50px;
    width: 50px;
  }

  .footer-links-block.footer-logo {
    flex-direction: column;
    width: 94%;
    height: auto;
    align-items: flex-start;
    margin-left: 0;
  }

  .nav-title {
    height: 110px;
    width: 340px;
    margin-left: 0;
  }
}
</style>