import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // { Example of how redirection after page reload works!
  //
  //   path: "/?redirect=structure",
  //   redirect: "/structure",
  // },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/public-documents",
    name: "PublicDocuments",
    component: () => import("../views/PublicDocuments.vue"),
  },
  {
    path: "/structure",
    name: "Structure",
    component: () => import("../views/Structure.vue"),
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import("../views/Contacts.vue"),
  },
  {
    path: "/life",
    name: "Life",
    component: () => import("../views/Life.vue"),
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../views/News.vue"),
  },
  {
    path: "/news/:id",
    name: "NewsItem",
    component: () => import("../views/NewsItem.vue"),
  },
  {
    path: "/admin/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "*",
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
