<template>
  <div id="menu-slider" class="menu-slider menu-closed">
    <div class="menu-navigation">
      <div class="menu-contacts">
        <a
          target="_blank"
          href="https://www.google.com/maps/place/%D0%A6%D0%B5%D0%BD%D1%82%D1%80+%D0%BF%D0%B5%D1%80%D0%B2%D0%B8%D0%BD%D0%BD%D0%BE%D1%97+%D0%BC%D0%B5%D0%B4%D0%B8%D0%BA%D0%BE-%D1%81%D0%B0%D0%BD%D1%96%D1%82%D0%B0%D1%80%D0%BD%D0%BE%D1%97+%D0%B4%D0%BE%D0%BF%D0%BE%D0%BC%D0%BE%D0%B3%D0%B8+%E2%84%962+%D0%94%D0%B5%D1%81%D0%BD%D1%8F%D0%BD%D1%81%D1%8C%D0%BA%D0%BE%D0%B3%D0%BE+%D1%80%D0%B0%D0%B9%D0%BE%D0%BD%D1%83/@50.5088143,30.5943991,16.31z/data=!4m13!1m7!3m6!1s0x40d4d1a90a738def:0x73d8ee6133ad5c82!2z0LLRg9C70LjRhtGPINCi0LXQvtC00L7RgNCwINCU0YDQsNC50LfQtdGA0LAsIDE5LCDQmtC40ZfQsiwgMDIwMDA!3b1!8m2!3d50.5092381!4d30.5966098!3m4!1s0x0:0x9c522c9eac21f4e1!8m2!3d50.509197!4d30.596647"
        >
          <div class="icon" style="background-image: url('/assets/contacts/placeholder.png');"></div>вул. Рональда Рейгана, 19
        </a>
        <a href="tel:+38(073) 537 1560">
          <div class="icon" style="background-image: url('/assets/contacts/phone-call.png');"></div>073 537 1560
        </a>
        <a>
          <div class="icon" style="background-image: url('/assets/contacts/clock.png');"></div>08:00 - 20:00
        </a>
      </div>
      <div
        @click="toggleMenuSlider"
        class="burger-menu-close"
        style="background-image: url('/assets/contacts/menu.png')"
      ></div>
    </div>
    <h2>КАРТА САЙТУ</h2>
    <ul class="site-map">
      <li>
        <a href="/">Головна</a>
      </li>
      <li>
        <a href="/public-documents">Публічна інформація</a>
      </li>
      <li>
        <a href="/contacts">Контакти</a>
      </li>
      <li>
        <a href="/structure">Структура</a>
      </li>
      <li>
        <a href="/life">Безбар'єрність</a>
      </li>
      <li>
        <a href="/news">Новини</a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "MenuSlider",
  methods: {
    toggleMenuSlider() {
      const menu = document.querySelector("#menu-slider");
      menu.classList.toggle("menu-closed");
    }
  }
};
</script>
<style scoped>
.menu-slider {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: white;
  z-index: 10000;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menu-closed {
  transform: translateX(100%);
}

.menu-navigation {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 70px 0;
}

.burger-menu-close {
  display: block;
  width: 60px;
  height: 60px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.menu-contacts {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.menu-contacts a {
  display: flex;
  align-items: center;
  font-size: 17px;
  color: black;
}

.menu-contacts a .icon {
  height: 20px;
  width: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto 12px;
}

.menu-contacts a::before {
  content: " ";
  display: inline-block;
  bottom: 0;
  left: 0;
  height: 30px;
  width: 2px;
  border-radius: 2px;
  margin: auto 0;
  background: grey;
}

h2 {
  font-size: 28px;
  margin: 10px 0 25px 30px;
}

ul.site-map {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 0 50px;
}

ul.site-map li {
  text-transform: underline;
}

ul.site-map a {
  font-size: 22px;
  color: #2392c5;
  margin: 5px 0;
}
</style>