<template>
  <div id="app">
    <div class="contacts-primary">
      <div class="contacts-primary-wrap">
        <a
          target="_blank"
          href="https://www.google.com/maps/place/%D0%A6%D0%B5%D0%BD%D1%82%D1%80+%D0%BF%D0%B5%D1%80%D0%B2%D0%B8%D0%BD%D0%BD%D0%BE%D1%97+%D0%BC%D0%B5%D0%B4%D0%B8%D0%BA%D0%BE-%D1%81%D0%B0%D0%BD%D1%96%D1%82%D0%B0%D1%80%D0%BD%D0%BE%D1%97+%D0%B4%D0%BE%D0%BF%D0%BE%D0%BC%D0%BE%D0%B3%D0%B8+%E2%84%962+%D0%94%D0%B5%D1%81%D0%BD%D1%8F%D0%BD%D1%81%D1%8C%D0%BA%D0%BE%D0%B3%D0%BE+%D1%80%D0%B0%D0%B9%D0%BE%D0%BD%D1%83/@50.5088143,30.5943991,16.31z/data=!4m13!1m7!3m6!1s0x40d4d1a90a738def:0x73d8ee6133ad5c82!2z0LLRg9C70LjRhtGPINCi0LXQvtC00L7RgNCwINCU0YDQsNC50LfQtdGA0LAsIDE5LCDQmtC40ZfQsiwgMDIwMDA!3b1!8m2!3d50.5092381!4d30.5966098!3m4!1s0x0:0x9c522c9eac21f4e1!8m2!3d50.509197!4d30.596647"
        >
          <div class="icon" style="background-image: url('/assets/contacts/placeholder.png');"></div>вул. Рональда Рейгана, 19
        </a>
        <a href="tel:+38(073) 537 1560">
          <div class="icon" style="background-image: url('/assets/contacts/phone-call.png');"></div>073 537 1560
        </a>
        <a>
          <div class="icon" style="background-image: url('/assets/contacts/clock.png');"></div>08:00 - 20:00
        </a>
      </div>
    </div>
    <MenuSlider class="small-screen-nav" />
    <!-- <Intro />
    <Services />
    <NewsAndPartners />
    <NewsLifestyle />
    
    <div class="social-buttons">
      <a
        href="https://www.facebook.com/cpmsd2desna/"
        style="background-image: url('/assets/contacts/facebook-button.png');"
        target="_blank"
        class="social-button social-button-facebook"
      ></a>
      <a
        href="tel:+38(073) 537 1560"
        style="background-image: url('/assets/contacts/phone-button.png');"
        class="social-button social-button-phone"
      ></a>
    </div>-->
    <router-view />
    <Footer />
  </div>
</template>

<script>
// import Intro from "@/components/Intro";
// import Services from "@/components/Services";
// import NewsLifestyle from "@/components/NewsLifestyle";
import Footer from "@/components/Footer";
// import NewsAndPartners from "@/components/NewsAndPartners";
import MenuSlider from "@/components/MenuSlider";
const redirectionList = [
  "structure",
  "public-documents",
  "contacts",
  "life",
  "news",
  "adminLogin"
];

export default {
  name: "App",
  components: {
    // Intro,
    // Services,
    // NewsLifestyle,
    Footer,
    // NewsAndPartners,
    MenuSlider
  },
  beforeUpdate() {
    const redirect = this.$route.query.redirect;
    const isRouterRedirection = this.$route.path === "/" && redirect;
    if (!isRouterRedirection) return;
    let conditionToRedirect =
      redirectionList.includes(redirect) || redirect.startsWith("newsItem_");

    if (conditionToRedirect) {
      let newUrlPath = redirect;
      if (redirect === "adminLogin") newUrlPath = "admin/login";
      if (redirect.startsWith("newsItem_")) {
        const urlString = redirect.split("_");
        newUrlPath = `news/${urlString[1]}`;
      }
      return this.$router.push({
        path: newUrlPath,
        query: null
      });
    }

    // if (isRouterRedirection && this.$route.query.redirect === "structure") {
    //   this.$router.push({ path: "structure", query: null });
    // }
  }
};
</script>

<style>
*:not(.news-item-content > *, .news-item-content > * > *, .news-item-content
    > *
    > *
    > *) {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

body,
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.contacts-primary {
  height: 35px;
  background: #f3f3f3;
  border-bottom: 1px solid #80808046;
}

.contacts-primary-wrap {
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.contacts-primary-wrap a {
  margin-right: 7%;
  display: flex;
  align-items: center;
  color: inherit;
}

.contacts-primary-wrap a .icon {
  height: 20px;
  width: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto 12px;
}

.contacts-primary-wrap a::before {
  content: " ";
  display: inline-block;
  top: 0;
  left: 0;
  height: 25px;
  width: 2px;
  border-radius: 2px;
  margin: auto 0;
  background: grey;
}

@media screen and (max-width: 670px) {
  .contacts-primary {
    display: none;
    height: auto;
  }

  .contacts-primary-wrap {
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
</style>
